<template>
	<BaseLoadingText v-if="loading" />
	<template v-else>
		<!-- Positive Report -->
		<div v-if="ghosts || orphans" id="report">
			<h3>Discrepancies found</h3>
			<p>
				We cross-checked the list of <em>internal</em> {{ singular(relationType) }} ids from this
				{{ entityType }} object with all the {{ relationType }} that list this {{ entityType }} as
				{{ reverseRelation }}. Any internal ids that are not matched, are called "ghosts" while any
				unmatched {{ relationType }} that list this {{ entityType }} are are called "orphans".
			</p>
			<div class="column-wrap">
				<div class="column">
					<small>Internal</small>
					<h3>Ghosts:</h3>
					<template v-if="ghosts">
						<ol>
							<li v-for="id in ghosts" :key="id">
								<router-link
									:to="{
										name: 'AdminInspectEntity',
										params: {
											entityType: singular(relationType),
											id_username_namePath: id || 'blank', // Need this or blank id breaks UI
										},
									}"
									>{{ id }}</router-link
								>
							</li>
						</ol>
						<div class="buttons-wrap">
							<FormButton :level="2" small value="Clear all" @click="clearAllGhosts" />
						</div>
					</template>
					<p v-else>-</p>
				</div>

				<div class="column">
					<small>External</small>
					<h3>Orphans:</h3>
					<template v-if="orphans">
						<ol>
							<li v-for="id in orphans" :key="id">
								<router-link
									:to="{
										name: 'AdminInspectEntity',
										params: {
											entityType: singular(relationType),
											id_username_namePath: id || 'blank', // Need this or blank id breaks UI
										},
									}"
									>{{ id }}</router-link
								>
							</li>
						</ol>
						<div class="buttons-wrap">
							<FormButton
								:level="1"
								small
								value="Delete all"
								@click="deleteAllOrphans"
								class="bumpB"
							/>
							<FormButton :level="1" small value="Adopt all" @click="adoptAllOrphans()" />
						</div>
					</template>
					<p v-else>-</p>
				</div>
			</div>
			<template v-if="relationType == 'artworks'">
				<p>
					What to do: Ghosts should probably be discarded as they don't exist. Orphans could be
					"adopted" but they might be duplicates of other artworks in which case they should be
					discarded.
				</p>
				<div class="buttons-wrap">
					<FormButton :level="1" small value="Review" @click="reviewDiscrepancies" />
				</div>
			</template>
		</div>

		<!-- Negative Report -->
		<div v-else id="report" class="clear">
			No discrepancies found
		</div>

		<!-- Artworks List -->
		<!-- When an artist has artwork discrepancies you can review them -->
		<template v-if="entityArtworks.length || artworksByEntity.length">
			<div id="options">
				<FormDropdown id="list" :options="pageSizeDdOptions" v-model="pageSize" />
			</div>

			<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" />

			<div id="image-list-wrap">
				<!-- Entity Artworks -->
				<div class="image-list">
					<p>{{ possessive(titlefy(entityType)) }} artworks</p>
					<div
						v-for="(artwork, i) in entityArtworksMarked"
						:key="i"
						:class="classType(artwork)"
						class="img-wrap"
					>
						<router-link
							target="_blank"
							:to="{
								name: 'EntityById',
								params: {
									id: artwork.id || 'blank',
								},
							}"
						>
							<BaseImageArt :itm="artwork" imgSize="small" />
						</router-link>
						<div v-if="artwork.ghost" class="buttons-wrap">
							<FormButton :level="1" small value="Clear" @click="clearOneGhost(artwork.id)" />
						</div>
					</div>
				</div>

				<!-- Artworks by Entity -->
				<div class="image-list">
					<p>Artworks marked with {{ possessive(entityType) }} id</p>
					<div
						v-for="(artwork, i) in artworksByEntityMarked"
						:key="i"
						:class="classType(artwork)"
						class="img-wrap"
					>
						<router-link
							target="_blank"
							:to="{
								name: 'EntityById',
								params: {
									id: artwork.id || 'blank',
								},
							}"
						>
							<BaseImageArt :itm="artwork" imgSize="small" />
						</router-link>
						<div v-if="artwork.orphan" class="buttons-wrap">
							<FormButton :level="1" small value="Adopt" @click="adoptOneOrphan(artwork.id)" />
							<FormButton
								:level="1"
								small
								value="Delete"
								@click="deleteOneOrphan(artwork.id)"
							/>
						</div>
					</div>
				</div>
			</div>

			<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :currentPage="page" />
		</template>
	</template>
</template>

<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import BaseLoadingText from '@/components/BaseLoadingText'
import FormButton from '@/components/FormButton'
import FormDropdown from '@/components/FormDropdown'
import BasePagination from '@/components/BasePagination'
import BaseImageArt from '@/components/BaseImageArt'

// Internal
import { possessive, titlefy, singular } from '@/use/StringMagic'
import flash from '@/use/FlashAlert'
import { pageSizeDdOptions } from '@/use/General'

export default {
	name: 'AdminInspectEntityDiscrep',
	components: {
		BaseLoadingText,
		FormButton,
		FormDropdown,
		BasePagination,
		BaseImageArt,
	},
	props: {
		// Passive
		id_username_namePath_wildcard: String,

		// Active
		id_username_namePath: {
			type: String,
			required: true,
		},
		entityType: {
			type: String,
			required: true,
		},
		relationType: {
			type: String,
			required: true,
		},
		entity: {
			type: Object,
			required: true,
		},
		page: {
			type: [Number, String],
			default: 1, // Ignored
		},
	},
	setup() {
		const dialogStore = useDialogStore()
		return {
			dialogStore,
			possessive,
			titlefy,
			singular,
			pageSizeDdOptions: pageSizeDdOptions(),
		}
	},
	data() {
		return {
			entityArtworks: [],
			artworksByEntity: [],
			ghosts: [],
			orphans: [],
			total: 0,
			pageCount: 1,
			pageSize: 10,
			loading: true,
			// Images are hidden until src loads
			imgOpacity: { opacity: 0 },
			// When review UI is open, we need to know
			// if we should reload it when updating data
			reviewing: false,
		}
	},
	computed: {
		// Entity artworks marked with ghosts
		entityArtworksMarked() {
			if (this.ghosts) {
				return this.entityArtworks.map(aw => {
					if (this.ghosts.includes(aw.id)) aw.ghost = true
					return aw
				})
			} else {
				return this.entityArtworks
			}
		},
		// Artworks by entity marked with orphans
		artworksByEntityMarked() {
			if (this.orphans) {
				return this.artworksByEntity.map(aw => {
					return this.orphans.includes(aw.id) ? { ...aw, orphan: true } : aw
				})
			} else {
				return this.artworksByEntity
			}
		},
		// Reverse relation from item to entity
		reverseRelation() {
			if (this.entityType == 'artist') {
				if (this.relationType == 'artworks') {
					return 'artist'
				}
			} else if (this.entityType == 'user') {
				if (this.relationType == 'artworks') {
					return 'collector'
				} else if (this.relationType == 'followers') {
					return 'following'
				} else if (this.relationType == 'following') {
					return 'followers'
				} else if (this.entityType == 'list' || this.relationType == 'rooms') {
					return 'owner'
				}
			} else if (this.entityType == 'artwork') {
				if (this.relationType == 'collectors') {
					return 'collected'
				}
			} else if (this.entityType == 'list' || this.entityType == 'room') {
				if (this.relationType == 'artworks') {
					return 'curator'
				}
			}
			return '(error)'
		},
		page_pageSize() {
			return this.page + '_' + this.pageSize
		},
	},
	watch: {
		// Note: there's a more elegant way of doing this
		// see Admin › Index › Entity.vue › beforeRouteUpdate
		async page_pageSize(newValue, oldValue) {
			newValue = newValue.split('_')
			oldValue = oldValue.split('_')
			const oldPage = +oldValue[0]
			const oldPageSize = +oldValue[1]
			const newPage = +newValue[0]
			const newPageSize = +newValue[1]

			if (newPageSize != oldPageSize) {
				// Changing pages size --> jump back tp page 1
				this.$router.replace({
					name: this.$route.name,
					params: { ...this.$route.params, page: 1 },
					query: { ...this.$route.query, pageSize: this.pageSize },
				})
				// When pageSize changes, page will be reset to one,
				// which will trigger another route change, which will
				// take care of reloading artworks
				if (oldPage == 1) this.reviewDiscrepancies()
			} else if (newPage != oldPage) {
				this.reviewDiscrepancies()
			}
		},
	},
	created() {
		// Load API
		this.adminInspectApi = this.loadApi('adminInspect')

		this.reportDiscrepancies()
	},
	methods: {
		// Initial report on page load
		async reportDiscrepancies() {
			const { status, data } = await this.adminInspectApi.reportDiscrepancies(
				this.entityType,
				this.relationType,
				this.entity.id
			)

			if (status == 200 && data) {
				const { ghosts, orphans } = data
				this.ghosts = ghosts
				this.orphans = orphans
			}
			this.loading = false
		},

		// List artworks and visualize orphans and ghosts
		async reviewDiscrepancies() {
			this.reviewing = true
			const { entityItems, itemsByEntity, total, pageCount } = (
				await this.adminInspectApi.reviewDiscrepancies(
					this.entityType,
					this.relationType,
					this.entity.id,
					this.pageSize,
					this.page || 1
				)
			).data
			this.entityArtworks = entityItems
			this.artworksByEntity = itemsByEntity
			this.total = total
			this.pageCount = pageCount
			this.goToPage1()
		},

		// Remove one ghost id
		clearOneGhost(ghostId) {
			// Note: this is a quite trivial action so
			// maybe we don't nee confirm?
			this.dialogStore.confirm(async () => {
				const response = await this.adminInspectApi.clearGhosts(
					this.entityType,
					this.relationType,
					this.entity.id,
					ghostId
				)
				this.handleResponse(response, 'Ghost successfully cleared.')
			})
		},

		// Remove all ghost ids
		clearAllGhosts() {
			this.dialogStore.confirm(async () => {
				const response = await this.adminInspectApi.clearGhosts(
					this.entityType,
					this.relationType,
					this.entity.id,
					this.ghosts.join(',')
				)
				this.handleResponse(response, 'Ghosts successfully cleared.')
			})
		},

		// Remove one orphan
		deleteOneOrphan(orphanId) {
			this.dialogStore.confirm(
				async () => {
					const response = await this.adminInspectApi.deleteOrphans(orphanId)
					this.handleResponse(response, 'Orphan successfully deleted.')
				},
				"You're about to delete this artwork.",
				{ warning: true, title: 'Are you sure?' }
			)
		},

		// Remove all orphans
		deleteAllOrphans() {
			this.dialogStore.confirm(
				async () => {
					const response = await this.adminInspectApi.deleteOrphans(this.orphans.join(','))
					this.handleResponse(response, 'Orphans successfully deleted.')
				},
				"Deleting orphans means you're deleting actual artworks. Are you sure you intend to delete all of them at once?",
				{ warning: true, title: 'Are you sure?' }
			)
		},

		adoptOneOrphan(orphanId) {
			this.dialogStore.confirm(async () => {
				const response = await this.adminInspectApi.adoptOrphans(
					this.entityType,
					this.relationType,
					this.entity.id,
					orphanId
				)
				this.handleResponse(response, 'Orphan successfully adopted.')
			})
		},

		adoptAllOrphans() {
			this.dialogStore.confirm(async () => {
				const response = await this.adminInspectApi.adoptOrphans(
					this.entityType,
					this.relationType,
					this.entity.id,
					this.orphans.join(',')
				)
				this.handleResponse(response, 'All orphans successfully adopted.')
			})
		},

		// Show success or error flash alert after orphan/ghost actions
		// Reload data on success
		handleResponse(response, successMessage) {
			if (response.status != 200) {
				flash(response.status + ' - ' + response.data, {
					type: 'error',
					persistent: true,
				})
			} else {
				flash(successMessage, {
					type: 'success',
				})
				this.reportDiscrepancies()
				if (this.reviewing) this.reviewDiscrepancies()
			}
		},

		// When there's pagination, we forward to page 1
		goToPage1() {
			if (!this.$route.params.page && this.total > this.pageSize) {
				this.$route.params.page = 1
				this.$router.replace(this.$route)
			}
		},

		// Mark orphans and ghosts
		classType(artwork) {
			if (artwork.ghost) {
				return 'ghost'
			} else if (artwork.orphan) {
				return 'orphan'
			} else {
				return ''
			}
		},

		// Handle missing src
		src(artwork) {
			return artwork.views ? artwork.views[0].image : 'missing.jpg'
		},
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity-discrep.scss"></style>
